<template lang="">
    <div>
        <BankDetails/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satıcı Detay", route: "manage/bank-details" },
    ]);
  },
    components: {
        BankDetails: () => import('@/components/manage/bank/BankDetail.vue')
    }
}
</script>
<style lang="">
    
</style>